<template>
  <div>
    <vs-button
      v-if="status == 'Draft'"
      class="mt-2"
      color="success"
      type="border"
      icon-pack="feather"
      icon="icon-check"
      @click="handleStatusMultiple('Assigned')"
      >Assign</vs-button
    >

    <div class="vx-input-group flex" v-if="status == 'Assigned'">
      <vs-button
        class="mt-2 mr-2"
        color="warning"
        type="border"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleStatusMultiple('Draft')"
        >Draft</vs-button
      >

      <vs-button
        class="mt-2 mr-2"
        color="danger"
        type="border"
        icon-pack="feather"
        icon="icon-x-circle"
        @click="handleStatusMultiple('Canceled')"
        >Cancel</vs-button
      >
    </div>

    <div class="vx-row">
      <div class="vx-col sm:w-1/1 w-full mt-base">
        Selected Route Assignment:
        {{ this.selectedRouteAssignmentCodes.join(", ") }}
      </div>
    </div>

    <vs-table
      search
      stripe
      border
      description
      :sst="true"
      :data="table.data"
      :max-items="table.length"
      :total="table.total"
      @search="handleSearch"
      @change-page="handleChangePage"
      @sort="handleSort"
    >
      <template slot="header">
        <vs-dropdown vs-trigger-click class="cursor-pointer">
          <div
            class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
          >
            <span class="mr-2"
              >{{ this.table.start }} - {{ this.table.end }} of
              {{ this.table.total }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item
              v-for="item in table.limits"
              :key="item"
              @click="handleChangelength(item)"
            >
              <span>{{ item }}</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </template>
      <template slot="thead">
        <th order v-if="status == 'Draft' || status == 'Assigned'">
          <input
            v-if="status == 'Draft' || status == 'Assigned'"
            type="checkbox"
            v-model="checkedAll"
            title="Check / Uncheck all"
          />
        </th>
        <vs-th v-if="status == 'Draft' || status == 'Assigned'"></vs-th>
        <vs-th sort-key="datetime">Code</vs-th>
        <vs-th sort-key="customer_name">Territory</vs-th>
        <vs-th sort-key="valid_from">Valid From</vs-th>
        <vs-th sort-key="valid_to">Valid To</vs-th>
        <vs-th sort-key="detail">Detail</vs-th>
        <vs-th sort-key="assigned_by" v-if="status == 'Assigned'"
          >Assigned By</vs-th
        >
        <vs-th sort-key="assigned_at" v-if="status == 'Assigned'"
          >Assigned On</vs-th
        >
        <vs-th sort-key="canceled_by" v-if="status == 'Invalid'"
          >Canceled By</vs-th
        >
        <vs-th sort-key="canceled_at" v-if="status == 'Invalid'"
          >Canceled On</vs-th
        >
        <vs-th sort-key="created_by">Created By</vs-th>
        <vs-th sort-key="created_at">Created On</vs-th>
        <vs-th sort-key="updated_by">Updated By</vs-th>
        <vs-th sort-key="updated_at">Updated On</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td v-if="status == 'Draft' || status == 'Assigned'">
            <input
              class="ml-2"
              type="checkbox"
              v-model="checked[indextr]"
              @input="
                onchangeSelectedRouteAssignment($event, indextr, data[indextr])
              "
            />
          </vs-td>
          <vs-td v-if="status == 'Draft' || status == 'Assigned'">
            <div class="vx-input-group flex">
              <vs-button
                v-if="status == 'Draft'"
                @click.stop="handleAssign(data[indextr].id)"
                size="small"
                color="success"
                icon-pack="feather"
                icon="icon-check"
                title="Assign"
                style="margin-right: 5px"
              />

              <vs-button
                v-if="status == 'Draft'"
                @click.stop="handleEdit(data[indextr].id)"
                size="small"
                color="warning"
                icon-pack="feather"
                icon="icon-edit"
                title="Edit"
                style="margin-right: 5px"
              />
              <vs-button
                v-if="status == 'Draft' || status == 'Invalid'"
                @click.stop="handleDelete(data[indextr].id)"
                size="small"
                color="danger"
                icon-pack="feather"
                icon="icon-trash"
                title="Delete"
              />
              <vs-button
                v-if="status == 'Assigned'"
                @click.stop="handleDraft(data[indextr].id)"
                size="small"
                color="warning"
                icon-pack="feather"
                icon="icon-arrow-left"
                title="Draft"
                style="margin-right: 5px"
              />
              <vs-button
                v-if="status == 'Assigned'"
                @click.stop="handleCancel(data[indextr].id)"
                size="small"
                color="danger"
                icon-pack="feather"
                icon="icon-x-circle"
                title="Cancel"
              />
            </div>
          </vs-td>
          <vs-td :data="data[indextr].code">
            {{ data[indextr].code }}
          </vs-td>
          <vs-td :data="data[indextr].territory_code">
            {{
              data[indextr].territory_code + " " + data[indextr].territory_name
            }}
          </vs-td>
          <vs-td :data="data[indextr].valid_from">
            {{ data[indextr].valid_from }}
          </vs-td>
          <vs-td :data="data[indextr].valid_to">
            {{ data[indextr].valid_to }}
          </vs-td>
          <vs-td :data="data[indextr].detail">
            <div v-html="data[indextr].detail"></div>
          </vs-td>

          <vs-td :data="data[indextr].assigned_by" v-if="status == 'Assigned'">
            {{ data[indextr].assigned_by }}
          </vs-td>
          <vs-td :data="data[indextr].assigned_at" v-if="status == 'Assigned'">
            {{ utcToLocal(data[indextr].assigned_at) }}
          </vs-td>
          <vs-td :data="data[indextr].canceled_by" v-if="status == 'Invalid'">
            {{ data[indextr].canceled_by }}
          </vs-td>
          <vs-td :data="data[indextr].canceled_at" v-if="status == 'Invalid'">
            {{ utcToLocal(data[indextr].canceled_at) }}
          </vs-td>

          <vs-td :data="data[indextr].created_by">
            {{ data[indextr].created_by }}
          </vs-td>
          <vs-td :data="data[indextr].created_at">
            {{ utcToLocal(data[indextr].created_at) }}
          </vs-td>
          <vs-td :data="data[indextr].updated_by">
            {{ data[indextr].updated_by }}
          </vs-td>
          <vs-td :data="data[indextr].updated_at">
            {{ utcToLocal(data[indextr].updated_at) }}
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>
    <vs-pagination
      style="padding-top: 5px"
      :total="table.totalPage"
      v-model="setPage"
    />
  </div>
</template>
<script>
import moment from "moment";
export default {
  components: {},
  props: {
    baseUrl: {
      type: String,
    },
    detail: {
      type: Boolean,
    },
    territoryIDs: {
      type: Array,
    },
    personalIDs: {
      type: Array,
    },
    productTeamIDs: {
      type: Array,
    },
    status: {
      type: String,
    },
    dateNow: {
      type: Date,
    },
  },
  data() {
    return {
      deleteId: null,
      table: this.tableDefaultState(),
      checkedAll: false,
      checked: [],
      selectedRouteAssignments: [],
      selectedRouteAssignmentIDs: [],
      selectedRouteAssignmentCodes: [],
    };
  },
  methods: {
    tableDefaultState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "id",
        sort: "desc",
        total: 0,
        totalPage: 1,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0,
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      this.table.order = key;
      this.table.sort = active;
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.$emit("close");
    },
    handleCreate() {
      this.customerId = null;
      this.detail = true;
    },
    handlePlan(id) {
      this.$router.push({
        name: "customer-visit-plan",
        params: { id: id },
      });
    },
    getData() {
      var order = this.table.order;
      var sort = this.table.sort;

      if (this.status == "Canceled") {
        order = "canceled_at";
        sort = "desc";
      }
      this.$vs.loading();
      this.$http
        .get(this.baseUrl + "/table", {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: order,
            sort: sort,
            territory_ids: this.territoryIDs,
            personal_ids: this.personalIDs,
            product_team_ids: this.productTeamIDs,
            status: this.status,
            date: moment(this.dateNow).format("YYYY-MM-DD"),
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.checkedAll = false;
            this.checked = [];
            this.selectedRouteAssignments = [];

            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;

      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;

      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }

      if (
        this.table.totalSearch < this.table.total &&
        this.table.search != ""
      ) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    handleDelete(id) {
      this.deleteId = id;
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Please confirm to delete this data",
        accept: this.acceptDelete,
      });
    },
    acceptDelete() {
      this.$vs.loading();
      this.$http
        .delete(this.baseUrl + "/" + this.deleteId + "/delete")
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "The data was successfully deleted",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
          this.getData();
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    handleEdit(id) {
      this.$router.push({
        name: "route-assignment-edit",
        params: { id: id },
      });
    },
    handleAssign(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to assign this data?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .put(this.baseUrl + "/" + id + "/status", {
              status: "Assigned",
            })
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "The data was successfully assigned",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }

              this.$vs.loading.close();
              this.getData();
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        },
      });
    },

    handleStatusMultiple(status) {
      let routeAssignmentIDs = [];
      this.selectedRouteAssignments.forEach((el) => {
        routeAssignmentIDs.push(el.id);
      });

      if (routeAssignmentIDs.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select data to assign",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to assign this data?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .put(this.baseUrl + "/status/multiple", {
              status: status,
              route_assignment_ids: routeAssignmentIDs,
            })
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "The data was successfully " + status,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }

              this.$vs.loading.close();
              this.getData();
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        },
      });
    },
    handleAssignMultiple() {
      let routeAssignmentIDs = [];
      this.selectedRouteAssignments.forEach((el) => {
        routeAssignmentIDs.push(el.id);
      });

      if (routeAssignmentIDs.length == 0) {
        this.$vs.notify({
          color: "danger",
          title: "Error",
          text: "Please select data to assign",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-x-circle",
        });
        return false;
      }
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to assign this data?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .put(this.baseUrl + "/status/multiple", {
              status: "Assigned",
              route_assignment_ids: routeAssignmentIDs,
            })
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "The data was successfully assigned",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }

              this.$vs.loading.close();
              this.getData();
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        },
      });
    },
    handleDraft(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure change to draft this data?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .put(this.baseUrl + "/" + id + "/status", {
              status: "Draft",
            })
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "The data was successfully change to draft",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }

              this.$vs.loading.close();
              this.getData();
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        },
      });
    },
    handleCancel(id) {
      this.$vs.dialog({
        type: "confirm",
        color: "danger",
        title: `Confirm`,
        text: "Are you sure to cancel this data?",
        accept: () => {
          this.$vs.loading();
          this.$http
            .put(this.baseUrl + "/" + id + "/status", {
              status: "Canceled",
            })
            .then((resp) => {
              if (resp.code == 200) {
                this.$vs.notify({
                  color: "success",
                  title: "Success",
                  text: "The data was successfully canceled",
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              } else {
                this.$vs.notify({
                  color: "danger",
                  title: "Error",
                  text: resp.message,
                  position: "top-right",
                  iconPack: "feather",
                  icon: "icon-x-circle",
                });
              }

              this.getData();
              this.$vs.loading.close();
            })
            .catch((error) => {
              this.$vs.loading.close();
              console.log(error);
            });
        },
      });
    },
    utcToLocal(val) {
      if (val) {
        return moment(val)
          .add(-7, "h")
          .utc()
          .local()
          .format("DD MMM YYYY HH:mm:ss");
      }
    },
    onchangeSelectedRouteAssignment(e, index, row) {
      console.log(row);
      if (!this.checked[index]) {
        var fitered = this.selectedRouteAssignments.filter((d) => {
          return d.id == row.id;
        });

        if (fitered.length == 0) {
          this.selectedRouteAssignments.push(row);
        }
      } else {
        this.selectedRouteAssignments.forEach((val, i) => {
          if (val.id == row.id) {
            this.selectedRouteAssignments.splice(i, 1);
          }
        });
        this.checkedAll = false;
      }

      this.setSelectedRouteAssignmets();
    },

    setSelectedRouteAssignmets() {
      this.selectedRouteAssignmentIDs = [];
      this.selectedRouteAssignmentCodes = [];
      this.selectedRouteAssignments.forEach((element) => {
        this.selectedRouteAssignmentIDs.push(element.id);
        this.selectedRouteAssignmentCodes.push(element.code);
      });
    },
  },
  mounted() {},
  watch: {
    status() {
      this.getData();
    },
    territoryIDs() {
      this.getData();
    },
    personalIDs() {
      this.getData();
    },
    productTeamIDs() {
      this.getData();
    },
    detail() {
      this.getData();
    },

    checkedAll(val) {
      if (val) {
        this.selectedRouteAssignments = [];
        this.table.data.forEach((el, index) => {
          this.checked[index] = true;
          this.selectedRouteAssignments.push(el);
        });
      } else {
        if (this.selectedRouteAssignments.length == this.checked.length) {
          this.checked.forEach((el, index) => {
            this.checked[index] = false;
          });
          this.selectedRouteAssignments = [];
        }
      }

      this.setSelectedRouteAssignmets();
    },
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        this.handleChangePage(val);
      },
    },
  },
};
</script>
